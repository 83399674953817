import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import SuccessBox from 'components/Web_User_Interface/1080p_Series/Multimedia/Privacy_Mask/SuccessBox';
import PrivacyTable from 'components/Web_User_Interface/1080p_Series/Multimedia/Privacy_Mask/privacyTable';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Multimedia Menu // Privacy Mask",
  "path": "/Web_User_Interface/1080p_Series/Multimedia/Privacy_Mask/",
  "dateChanged": "2017-12-11",
  "author": "Mike Polinowski",
  "excerpt": "Here you can adjust the amplification of the cameras microphones/audio-in as well as the volume of the audio-out signal.",
  "image": "./WebUI_1080p_SearchThumb_Multimedia_Privat.png",
  "social": "/images/Search/WebUI_1080p_SearchThumb_Multimedia_Privat.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_WebUI_white.webp",
  "chapter": "1080p Web User Interface"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='Multimedia Menu // Privacy Mask' dateChanged='2017-12-11' author='Mike Polinowski' tag='INSTAR IP Camera' description='Here you can adjust the amplification of the cameras microphones/audio-in as well as the volume of the audio-out signal.' image='/images/Search/WebUI_1080p_SearchThumb_Multimedia_Privat.png' twitter='/images/Search/WebUI_1080p_SearchThumb_Multimedia_Privat.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Web_User_Interface/1080p_Serie/Multimedia/Privat_Maske/' locationFR='/fr/Web_User_Interface/1080p_Series/Multimedia/Privacy_Mask/' crumbLabel="Privacy Mask" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "1080p-web-user-interface",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#1080p-web-user-interface",
        "aria-label": "1080p web user interface permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`1080p Web User Interface`}</h1>
    <h2 {...{
      "id": "multimedia-menu",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#multimedia-menu",
        "aria-label": "multimedia menu permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Multimedia Menu`}</h2>
    <EuiSpacer mdxType="EuiSpacer" />
    <SuccessBox mdxType="SuccessBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/d65019168e6536cc95df7296ff586758/29c1d/1080p_Settings_Multimedia_Privacy.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "70.43478260869564%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAA7EAAAOxAGVKw4bAAADM0lEQVQoz2MomrO9fMuNovWXC9dfyVt3uWDDpZx1l7LWXMpafS5r1dmcVRdy1oBQ/vpLeWsv5q4+n770ZMrCYykLj2YsPsawYcv2isb2iqaOipqWlobu1qaJiyZN2zFv7vpFm9as2rx4zcr5s5fMmz5/Ys+0OfNXLlm18fqDZx9+/Xv3/e+br78Z5s6YLi4uIS0lqSGv5Gdk6avrVe+d3hsV2RAW2ZSV254ZtLA4pTEmLdc5qCUjua6g4O7NG///////79/nHz8ZZk2fpqujaWdvG+ng5mdo355e1ZA0LSciIz8iLj0ovCIiujE+szOzoj8hLzkwwd876PLlq//////79++fP38YZk7uczRR8vVyq/B1irYy1lPWCjA2iXL3DAvLSgsNC3RwmZWVcrTW73B37PTqiRF2NqePH4do/v37D8Ok/gkMYCDOw9aY6GVvZO5noB7uYGGko6cgJRtgZzmv1LvJz2BlosaFCr2DOcZ3LpwEuRqk+TfDxL5+BgYGVlZmBgYmD12J5hDdvnSLKamGynJS+po6iYnR1nb2SrJaqnKyjmrCPW6Sjy6fgWj++/cvw6R+kGYWFhYGBkZFfsapyRorCw1TbY00FWRzE6MrysoUpOU0NTQkxKU5OHmNpPjugm3++/fv////GZYvXeLh6aGurcvHzOCjz8vBxW6sLHw4TavQWDA8NNTSw0tZXk5FTZWPn8fayiIrNvje7RsIzevXrYtJyYryc0oyY2ZjYWHn5EyQlJirwBsmI6RmZeSSGmAX6KRprycpLxUcGpSXk333zh1wTIE1379y+u2dkx+f3n79/OGtu/eePLj/+/6Dh3ce3Lj38N6De09fPv3w9f2rD68fPn705u2bN2/e/vnz5////6+//3v9/R/DuX0b3p1Z+fLBjVfvPr5/9/bd+/cv3797+/7d+3dg8Obt61ev375+8+7du9evX7969fr58+ePnjzddvbWtjO3GCoqqkqrm8rKK0tKisvLK6qrq6uqqqurq2tAsKYaA9TUgERbGmpbGuoYSopLikpKCopLS0rLq6qqyisqyysqK8EG1NTU1GOAurq6mpqayqrq0rIyAG0Pu1p+3nRSAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d65019168e6536cc95df7296ff586758/e4706/1080p_Settings_Multimedia_Privacy.avif 230w", "/en/static/d65019168e6536cc95df7296ff586758/d1af7/1080p_Settings_Multimedia_Privacy.avif 460w", "/en/static/d65019168e6536cc95df7296ff586758/7f308/1080p_Settings_Multimedia_Privacy.avif 920w", "/en/static/d65019168e6536cc95df7296ff586758/f5b30/1080p_Settings_Multimedia_Privacy.avif 1375w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/d65019168e6536cc95df7296ff586758/a0b58/1080p_Settings_Multimedia_Privacy.webp 230w", "/en/static/d65019168e6536cc95df7296ff586758/bc10c/1080p_Settings_Multimedia_Privacy.webp 460w", "/en/static/d65019168e6536cc95df7296ff586758/966d8/1080p_Settings_Multimedia_Privacy.webp 920w", "/en/static/d65019168e6536cc95df7296ff586758/fbb33/1080p_Settings_Multimedia_Privacy.webp 1375w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d65019168e6536cc95df7296ff586758/81c8e/1080p_Settings_Multimedia_Privacy.png 230w", "/en/static/d65019168e6536cc95df7296ff586758/08a84/1080p_Settings_Multimedia_Privacy.png 460w", "/en/static/d65019168e6536cc95df7296ff586758/c0255/1080p_Settings_Multimedia_Privacy.png 920w", "/en/static/d65019168e6536cc95df7296ff586758/29c1d/1080p_Settings_Multimedia_Privacy.png 1375w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/d65019168e6536cc95df7296ff586758/c0255/1080p_Settings_Multimedia_Privacy.png",
              "alt": "Web User Interface - 1080p Series - Multimedia Privacy Mask",
              "title": "Web User Interface - 1080p Series - Multimedia Privacy Mask",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <PrivacyTable mdxType="PrivacyTable" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h2 {...{
      "id": "black-out-areas-from-your-surveillance",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#black-out-areas-from-your-surveillance",
        "aria-label": "black out areas from your surveillance permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Black out Areas from your Surveillance`}</h2>
    <p>{`The Privacy Mask Menu allows you to black out areas from your surveillance. E.g. you can still see a part of the public street in front of your house on your surveillance videos. But you cannot adjust the camera angle any more. Now you can setup up to 4 masks to prevent the street from showing up in your surveillance footage - just activate an area and draw its shape onto the image.`}</p>
    <p><strong parentName="p">{`Tip:`}</strong>{` You can use the privacy mask to hide from the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Alarm/Areas/"
      }}>{`Motion Detection Areas`}</a>{` to avoid false triggers.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      